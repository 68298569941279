import { useEffect, useState } from 'react'
import SearchCard from '../cards/SearchCard'
import Loader from 'react-loader-spinner'
import {
  InstantSearch,
  Hits,
  SearchBox,
  RefinementList,
} from 'react-instantsearch-dom'
import SearchImage from '../../public/img/search.svg'

const ModalSearch = ({ searchState, closeModal, algoliaClient, modalSearchState, setModalSearchState }) => {

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [tabState, setTabState] = useState('videos')
  const [hideSubmit, setHideSubmit] = useState(false)
  const [searchCharacters] = useState(3)
  const [showSubmit, setShowSubmit] = useState(true)

  const toggleTab = e => {
    let dataset = e.target.dataset.name
    setTabState(dataset)
    console.log('dataset -> ', dataset)
  }

  const CustomHits = ({ hit }) => {
    console.log('hit custom hits -> ', hit, tabState)

    let card = null

    if (tabState === 'videos') {
      card = {
        __typename: 'Video',
        event: { slug: hit?.event?.slug },
        thumbnail_url: hit?.thumbnail_url,
        title: hit?.title,
        slug: hit?.slug,
        kaltura_id: hit?.kaltura_id,
        author: hit?.authors ? hit?.authors[0] : '',
      }
    }

    if (tabState === 'events') {
      card = {
        __typename: 'Event',
        title: hit?.title,
        status: hit?.status,
        slug: hit?.slug,
        authors: hit?.authors ?? '',
        upcoming_thumb_url: hit?.upcoming_thumb_url,
        poster_url: hit?.poster_url,
        banner_url: hit?.banner_url,
      }

    }

    return <SearchCard closeModal={closeModal} card={card} />
  }

  useEffect(() => {
    if (modalSearchState)
      document.body.style.overflow = 'hidden'

    if (!modalSearchState)
      document.body.style.overflow = 'auto'

  }, [modalSearchState])


  useEffect(() => {
  }, [tabState])

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      const isEmpty = requests.every(({ params }) => !params.query)
      const hasEnoughCharacters = requests.every(({ params }) => params.query.length >= searchCharacters)

      if (isEmpty) return
      if (!hasEnoughCharacters) return

      return algoliaClient.search(requests)
    },
  }

  return (
    <>
      <InstantSearch searchClient={searchClient} indexName={tabState}>
        <div className="search-panel">

          <div className="search-panel__filters">
            <RefinementList attribute="brand" />
          </div>

          <div className=''>
            <SearchBox translations={{ placeholder: 'Search', }}
              onReset={() => { setHideSubmit(false), setModalSearchState(false) }}
              onChange={event => {
                const totalCharacters = event.currentTarget.value?.length
                console.log('totalCharacters -> ', totalCharacters)

                if (totalCharacters >= 3)
                  setHideSubmit(true), setModalSearchState(true)

                if (totalCharacters < 3)
                  setHideSubmit(false), setModalSearchState(false)

                if (totalCharacters === 0)
                  setShowSubmit(true)

                if (totalCharacters > 0)
                  setShowSubmit(false)
              }}
              submit={<SearchImage className={`h-5 w-5 text-gray-400 ${showSubmit ? '' : 'hidden'}`} />}
              className="block w-full pl-5 pr-3 py-2 border border-transparent leading-5 bg-gray-100 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm rounded-full" />
          </div>

          {
            (modalSearchState && hideSubmit) &&
            <div className={`results-panel absolute`}>
              <Hits hitComponent={CustomHits} />
            </div>
          }

        </div>

      </InstantSearch>


      {
        modalSearchState &&
        <div className="fixed z-60 right-0 bottom-0 left-0 top-16">
          <div className="flex items-start justify-start min-h-screen lg:pt-4 lg:px-72 lg:pb-20 sm:p-0">
            <div className="absolute inset-0 transition-opacity" aria-hidden="true">
              <div className="fixed right-0 bottom-0 left-0 top-16 bg-gray-900"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
              className="inline-block align-start text-left transform transition-all mt-6 w-full h-screen px-3 lg:px-0"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline">
              <p className="text-white text-2xl tracking-wide font-bold">
                Resultados
                {
                  loading &&
                  <Loader className="inline-block" type="ThreeDots" color="#FFFFFF" height={8} width={40} />
                }
              </p>

              <div className="flex items-center mt-5 mb-3">
                <p className={`text-sm font-semibold cursor-pointer uppercase mr-5 ${tabState === 'videos' ? 'text-white' : 'text-gray-400'}`}
                  data-name="videos"
                  onClick={toggleTab}>
                  Videos
                </p>
                <p className={`text-sm font-semibold cursor-pointer uppercase mr-5 ${tabState === 'events' ? 'text-white' : 'text-gray-400'}`}
                  data-name="events"
                  onClick={toggleTab}>
                  Eventos
                </p>

              </div>

              <div className="w-full h-3/4 overflow-y-auto px-3">

              </div>

            </div>
          </div>
        </div>
      }
    </>

  )
}

export default ModalSearch
